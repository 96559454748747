/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// 2022-10-15 
// ポップオーバーのスクロールバーを非表示にするために
// ion-contentsに設定するために追加
.no-scroll {
    --overflow: hidden;
}

.screen-center {
    // 2022-10-17
    // 画面中央に配置するためにネットから引用したが
    // 入力項目をクリックしたりすると位置がずれる
    /*
    margin: 50vh auto 0;
    transform: translateY(-50%);
    text-align: center;
    */

    // 2022-10-18
    // ネットから引用してみたが全く中央なる気配なし
    /*
    display: table !important;
    width: 100% !important;
    height: 100% !important;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    */

    // 2022-10-18
    // ネットから引用して期待通りの効果を発揮
    // ion-grid に直接指定したところ何故か均等割り付けになった
    // ion-gridコンテンツを中央配置にしたいときにはdivで囲む必要がある
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-items: center;
}